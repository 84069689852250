var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1 pa-1"},[_c('v-card-title',{staticClass:"pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t("employee.index")))]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-2",staticStyle:{"width":"100px !important"},attrs:{"append-icon":"mdi-magnify","placeholder":_vm.$t('search'),"outlined":"","dense":"","single-line":"","hide-details":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getList.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"ma-1",staticStyle:{"border":"1px solid #aaa"},attrs:{"dense":"","fixed-header":"","loading-text":_vm.$t('loadingText'),"no-data-text":_vm.$t('noDataText'),"height":_vm.screenHeight,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","server-items-length":_vm.server_items_length,"options":_vm.dataTableOptions,"disable-pagination":true,"footer-props":{
        itemsPerPageOptions: [20, 50, 100, -1],
        itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
        itemsPerPageText: _vm.$t('itemsPerPageText'),
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }},on:{"update:options":function($event){_vm.dataTableOptions=$event},"update:page":_vm.updatePage,"update:items-per-page":_vm.updatePerPage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm.items .map(function (x) { return x.id; }) .indexOf(item.id) + _vm.from))]}},{key:"header.tabel",fn:function(ref){
      var header = ref.header;
return [_c('span',{staticStyle:{"white-space":"normal","width":"50px"}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.tabel",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'/users/profile/' + item.id}},[_vm._v(_vm._s(item.tabel))])]}},{key:"item.department_code",fn:function(ref){
      var item = ref.item;
return _vm._l((item.employee_staff),function(itm,idx){return _c('v-row',{key:idx,staticStyle:{"max-width":"100px"}},[_c('v-col',{staticClass:"col-12 text-truncate py-0"},[_vm._v(" "+_vm._s(itm.staff && itm.staff.department ? itm.staff.department.department_code : "")+" ")])],1)})}},{key:"header.department_code",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"white-space":"normal","max-width":"70px"}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.department_id",fn:function(ref){
      var item = ref.item;
return _vm._l((item.employee_staff),function(itm,idx){return _c('v-row',{key:idx,staticStyle:{"max-width":"350px"}},[_c('v-col',{staticClass:"col-12 text-truncate py-0"},[_vm._v(" "+_vm._s(itm.staff && itm.staff.department ? itm.staff.department["name_" + _vm.$i18n.locale] : "")+" ")])],1)})}},{key:"item.info",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "uz_latin" ? item.lastname_uz_latin + " " + item.firstname_uz_latin + " " + item.middlename_uz_latin : item.lastname_uz_cyril + " " + item.firstname_uz_cyril + " " + item.middlename_uz_cyril)+" ")]}},{key:"item.category",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"d-block ma-0 pa-0",staticStyle:{"white-space":"normal","min-width":"50px"}},[_vm._v(_vm._s(item.tariff_scale ? item.tariff_scale.category : ""))])]}},{key:"item.position",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.main_staff ? item.main_staff[0].position["name_" + _vm.$i18n.locale] : "")+" ")]}},{key:"item.employee_staff",fn:function(ref){
      var item = ref.item;
return _vm._l((item.employee_staff),function(itm,idx){return _c('span',{key:idx,staticClass:"d-block ma-0 pa-0",staticStyle:{"white-space":"normal","min-width":"50px"}},[_vm._v(_vm._s(itm.first_work_date))])})}},{key:"item.staffs",fn:function(ref){
      var item = ref.item;
return _vm._l((item.employee_staff),function(itm,idx){return _c('v-row',{key:idx,staticStyle:{"max-width":"350px"}},[(itm.staff && itm.staff.position)?_c('v-col',{staticClass:"col-12 text-truncate py-0"},[_vm._v(_vm._s(itm.staff.position["name_" + _vm.$i18n.locale]))]):_vm._e()],1)})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }